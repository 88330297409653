import { GET, POST } from '../api/fetch-api';

class ApiService {
	constructor() {
		this.berezkaApiUrl = process.env.REACT_APP_BEREZKA_API_URL;
		this.deployerUrl = process.env.REACT_APP_DEPLOYER_API_URL;
	}

	/**
	 *	Deployer
	 */

	rpcCall(body) {
		return POST(`${this.deployerUrl}/rpc/call`, body);
	}

	getRoles() {
		const body = {
			method: 'dao.aragon.getRoles',
			params: [],
		};

		return this.rpcCall(body);
	}

	getNetworkStats(chainId) {
		return GET(`${this.deployerUrl}/stats/${chainId}`);
	}

	getForward(executor, method, args, dao_id, caller) {
		const body = {
			method: 'dao.forward.getForward',
			params: [executor, method, args, dao_id, caller],
		};
		return this.rpcCall(body);
	}

	createWallet() {
		return POST(`${this.deployerUrl}/wallets`);
	}

	createRequest(net, tx_hash, params) {
		const body = {
			net,
			tx_hash,
			params,
		};

		return POST(`${this.deployerUrl}/requests`, body);
	}

	getRequestStatus(id) {
		return GET(`${this.deployerUrl}/requests/${id}/status`);
	}

	/**
	 * DAOs
	 */

	getDAOs(
		perPage = 20,
		page = 1,
		sort = '',
		filter = '',
		visible_flag = false,
	) {
		const sortQuery = sort ? `&sort=${sort}` : '';
		const filterQuery = filter ? `&name=${filter}` : '';
		const visibleQuery = visible_flag ? `&visible_flag=${visible_flag}` : '';
		return GET(
			`${this.berezkaApiUrl}/daos?per_page=${perPage}&page=${page}${filterQuery}${sortQuery}${visibleQuery}`,
		);
	}

	getDAOCanMange(id, address) {
		return GET(`${this.berezkaApiUrl}/daos/${id}/can_manage/${address}`);
	}

	getDAOVotes(id, perPage = 1000, page = 1, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/aragonv1/votes?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getDAORedeemableTokens(id) {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/aragonv1/get_redeemable_tokens`,
		);
	}

	/**
	 * DAO's Info
	 */

	getAnalyticsInfo(perPage = 1, page = 0, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/analytics_info?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getLeaderboard(perPage = 5, page = 1, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/leaderboard?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getDAORewards(id, perPage = 5, page = 1, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/aragonv1/rewards?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getDAOTokenRequests(id, perPage = 5, page = 1, sort = '') {
		const sortQuery = sort
			? `&sort=${sort},requester_address`
			: 'requester_address';
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/aragonv1/token_requests?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getDAOPermissions(id, perPage = 5, page = 1, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/aragonv1/permissions?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	getDAO(id) {
		return GET(`${this.berezkaApiUrl}/daos/${id}`);
	}

	getDAOContracts(id) {
		return GET(`${this.berezkaApiUrl}/daos/${id}/contracts`);
	}

	getDAOPortfolio(id, perPage = 10000, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/portfolio?per_page=${perPage}&page=${page}`,
		);
	}

	getDAOTokenHolders(id, address, page = '1', per_page = '1000') {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/token/${address}/holders?per_page=${per_page}&page=${page}`,
		);
	}

	getDAORoiMonthly(id, perPage = 10000, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/roi_monthly?per_page=${perPage}&page=${page}`,
		);
	}

	getDAOTokenPriceHistory(id, perPage = 1, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/token_price_history?per_page=${perPage}&page=${page}&validated=true`,
		);
	}

	getDAOTokenPriceHistoryDaily(id, perPage = 10000, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/daos/${id}/token_price_history_daily?per_page=${perPage}&page=${page}`,
		);
	}

	getDAOCreateTokenRequestTx(id, body) {
		return POST(`${this.berezkaApiUrl}/daos/${id}/token_request/create`, body);
	}

	finaliseDAOTokenRequest(id, body) {
		return POST(
			`${this.berezkaApiUrl}/daos/${id}/token_request/finalise`,
			body,
		);
	}

	withdrawalDAO(id, body) {
		return POST(`${this.berezkaApiUrl}/daos/${id}/withdrawal`, body);
	}

	/**
	 * DAO's Wallets
	 */

	getDAOWallets(id) {
		return GET(`${this.berezkaApiUrl}/daos/${id}/wallets`);
	}

	/**
	 * Client
	 */

	getClientDAOs(address, perPage = 1000, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/clients/${address}/daos?per_page=${perPage}&page=${page}`,
		);
	}

	getClientAnalytics(address) {
		return GET(`${this.berezkaApiUrl}/clients/${address}/info`);
	}

	getClientTokenRequests(address, perPage = 5, page = 1, sort = '') {
		const sortQuery = sort ? `&sort=${sort}` : '';
		return GET(
			`${this.berezkaApiUrl}/clients/${address}/aragonv1/token_requests?per_page=${perPage}&page=${page}${sortQuery}`,
		);
	}

	/**
	 *	Dictionaries
	 */

	getBlockchains(perPage = 100, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/blockchains?per_page=${perPage}&page=${page}`,
		);
	}

	getBlockchainsTokens(bcId, stable, perPage = 5, page = 1) {
		return GET(
			`${this.berezkaApiUrl}/blockchains/${bcId}/tokens?is_stable=${stable}&per_page=${perPage}&page=${page}`,
		);
	}

	/**
	 * Actions
	 */

	getDaoDeployPrepareTxData(daoTypeId, blockchainId, refCode, body) {
		return POST(
			`${this.berezkaApiUrl}/actions/dao_deploy_prepare_tx_data/${daoTypeId}/${blockchainId}/${refCode}`,
			body,
		);
	}

	/**
	 * Misc
	 */

	getChartData(id, chartType, timestampStart, timestampEnd) {
		let chartUrl;

		switch (chartType) {
			case 'tokenPrice':
			case 'net':
			case 'apy':
				chartUrl = 'token_price_history_daily';
				break;
			default:
				chartUrl = '';
		}

		if (!chartUrl) {
			return null;
		}

		return GET(
			`${this.berezkaApiUrl}/daos/${id}/${chartUrl}?bg=${timestampStart}&fn=${timestampEnd}&per_page=10000&page=1`,
		);
	}
}

export default new ApiService();
