import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const StyledMenu = styled.nav`
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	gap: 64px;
	background: ${COLORS.BLACK};
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
	height: 100vh;
	width: 100%;
	text-align: left;
	padding: 80px 100px 40px 40px;
	position: absolute;
	top: 0;
	right: 0;
	transition: transform 0.3s ease-in-out;
	z-index: 10;
	overflow: auto;

	@media (max-width: 992px) {
		display: flex;
	}

	@media (max-width: 576px) {
		padding: 80px 24px 24px 24px;
	}
`;

export const MobileRoutes = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;

	.route {
		font-weight: 400;
		font-size: 48px;
		line-height: 170%;
		letter-spacing: 0.02em;
		color: ${COLORS.WHITE};
		text-decoration: none;

		&.route-active {
			color: ${COLORS.DARK_GRAY};
		}

		&:hover {
		}
	}

	@media (max-width: 576px) {
		.route {
			font-size: 32px;
			line-height: 150%;
		}
	}
`;
