export const AGENT_ABI = [
	{
		constant: true,
		inputs: [],
		name: 'ADD_PROTECTED_TOKEN_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'hasInitialized',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'ERC1271_INTERFACE_ID',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'ERC1271_RETURN_INVALID_SIGNATURE',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'TRANSFER_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_script',
				type: 'bytes',
			},
		],
		name: 'getEVMScriptExecutor',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getRecoveryVault',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'RUN_SCRIPT_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'SAFE_EXECUTE_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'REMOVE_PROTECTED_TOKEN_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
			{
				name: '_value',
				type: 'uint256',
			},
		],
		name: 'deposit',
		outputs: [],
		payable: true,
		stateMutability: 'payable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'isDepositable',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'DESIGNATE_SIGNER_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'EXECUTE_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'address',
			},
		],
		name: 'allowRecoverability',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'appId',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [],
		name: 'initialize',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		name: 'protectedTokens',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getInitializationBlock',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'ERC1271_RETURN_VALID_SIGNATURE',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'transferToVault',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_sender',
				type: 'address',
			},
			{
				name: '_role',
				type: 'bytes32',
			},
			{
				name: '_params',
				type: 'uint256[]',
			},
		],
		name: 'canPerform',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getEVMScriptRegistry',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'designatedSigner',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'PROTECTED_TOKENS_CAP',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'ADD_PRESIGNED_HASH_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		name: 'isPresigned',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
			{
				name: '_to',
				type: 'address',
			},
			{
				name: '_value',
				type: 'uint256',
			},
		],
		name: 'transfer',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'kernel',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'isPetrified',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'balance',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		payable: true,
		stateMutability: 'payable',
		type: 'fallback',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'sender',
				type: 'address',
			},
			{
				indexed: true,
				name: 'target',
				type: 'address',
			},
			{
				indexed: false,
				name: 'data',
				type: 'bytes',
			},
		],
		name: 'SafeExecute',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'sender',
				type: 'address',
			},
			{
				indexed: true,
				name: 'target',
				type: 'address',
			},
			{
				indexed: false,
				name: 'ethValue',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'data',
				type: 'bytes',
			},
		],
		name: 'Execute',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
		],
		name: 'AddProtectedToken',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
		],
		name: 'RemoveProtectedToken',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'sender',
				type: 'address',
			},
			{
				indexed: true,
				name: 'hash',
				type: 'bytes32',
			},
		],
		name: 'PresignHash',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'sender',
				type: 'address',
			},
			{
				indexed: true,
				name: 'oldSigner',
				type: 'address',
			},
			{
				indexed: true,
				name: 'newSigner',
				type: 'address',
			},
		],
		name: 'SetDesignatedSigner',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
			{
				indexed: true,
				name: 'operator',
				type: 'address',
			},
			{
				indexed: true,
				name: 'from',
				type: 'address',
			},
			{
				indexed: false,
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'data',
				type: 'bytes',
			},
		],
		name: 'ReceiveERC721',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
			{
				indexed: true,
				name: 'to',
				type: 'address',
			},
			{
				indexed: false,
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'VaultTransfer',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
			{
				indexed: true,
				name: 'sender',
				type: 'address',
			},
			{
				indexed: false,
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'VaultDeposit',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'executor',
				type: 'address',
			},
			{
				indexed: false,
				name: 'script',
				type: 'bytes',
			},
			{
				indexed: false,
				name: 'input',
				type: 'bytes',
			},
			{
				indexed: false,
				name: 'returnData',
				type: 'bytes',
			},
		],
		name: 'ScriptResult',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'vault',
				type: 'address',
			},
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
			{
				indexed: false,
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'RecoverToVault',
		type: 'event',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_target',
				type: 'address',
			},
			{
				name: '_ethValue',
				type: 'uint256',
			},
			{
				name: '_data',
				type: 'bytes',
			},
		],
		name: 'execute',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_target',
				type: 'address',
			},
			{
				name: '_data',
				type: 'bytes',
			},
		],
		name: 'safeExecute',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'addProtectedToken',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'removeProtectedToken',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_hash',
				type: 'bytes32',
			},
		],
		name: 'presignHash',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_designatedSigner',
				type: 'address',
			},
		],
		name: 'setDesignatedSigner',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_operator',
				type: 'address',
			},
			{
				name: '_from',
				type: 'address',
			},
			{
				name: '_tokenId',
				type: 'uint256',
			},
			{
				name: '_data',
				type: 'bytes',
			},
		],
		name: 'onERC721Received',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'isForwarder',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'pure',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_interfaceId',
				type: 'bytes4',
			},
		],
		name: 'supportsInterface',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'pure',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_evmScript',
				type: 'bytes',
			},
		],
		name: 'forward',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_sender',
				type: 'address',
			},
			{
				name: '_evmScript',
				type: 'bytes',
			},
		],
		name: 'canForward',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_hash',
				type: 'bytes32',
			},
			{
				name: '_signature',
				type: 'bytes',
			},
		],
		name: 'isValidSignature',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_data',
				type: 'bytes',
			},
			{
				name: '_signature',
				type: 'bytes',
			},
		],
		name: 'isValidSignature',
		outputs: [
			{
				name: '',
				type: 'bytes4',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getProtectedTokensLength',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
];
