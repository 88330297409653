import React from 'react';
import { NavLink } from 'react-router-dom';
import { WeeziLogo } from '../WeeziLogo';
import { COLORS } from '../../models/colors';

const HomeLogoLink = ({ color = COLORS.BLACK, visible = true }) => {
	return (
		<NavLink to={'/'} style={{ display: visible ? 'block' : 'none' }}>
			<WeeziLogo color={color} />
		</NavLink>
	);
};

export default HomeLogoLink;
