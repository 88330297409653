import React from 'react';
import useTimeout from '../../hooks/useTimeout';
import { WeeziToast, WeeziToastText } from './styled';

export const Toast = (props) => {
	useTimeout(props.close, 3000);

	return (
		<WeeziToast onClick={props.close}>
			<WeeziToastText>{props.children}</WeeziToastText>
		</WeeziToast>
	);
};
