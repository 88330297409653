import React from 'react';
import { StyledBurger } from './styled';

const Burger = ({ color, open, setOpen, ...props }) => {
	const isExpanded = !!open;

	const triggerMenu = () => {
		if (open) {
			document.documentElement.style.overflow = 'auto';
		} else {
			document.documentElement.style.overflow = 'hidden';
		}
		setOpen(!open);
	};

	return (
		<StyledBurger
			aria-label="Toggle menu"
			aria-expanded={isExpanded}
			open={open}
			onClick={() => triggerMenu()}
			color={color}
			{...props}>
			<span />
			<span />
			<span />
		</StyledBurger>
	);
};

export default Burger;
