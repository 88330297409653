import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderService from '../../services/header.service';
import {
	HeaderHome,
	HeaderRoutes,
	HeaderTemplateData,
	HeaderToolbar,
	HeaderWrapper,
} from './styled';
import { docsUrl, routes } from './routes';
import { COLORS } from '../../models/colors';
import { HeaderWallet } from './HeaderWallet';
import { HomeLogoLink } from '../HomeLogoLink';
import { BackLink } from './BackLink';
import { Burger } from '../Burger';
import { MobileMenu } from '../MobileMenu';

const Header = () => {
	const [header, setHeader] = useState({
		color: 'green',
		logoColor: COLORS.WHITE,
		visibleRoutes: true,
		visibleBack: false,
		templateData: null,
		backLinkUrl: '/',
	});

	const [open, setOpen] = useState(false);

	useEffect(() => {
		const state$ = HeaderService.state$.subscribe((value) => {
			let logoColor = '';
			switch (value.color) {
				case 'green':
					logoColor = COLORS.WHITE;
					break;
				default:
					logoColor = COLORS.BLACK;
			}

			if (value.showNav) {
				setHeader({
					color: value.color,
					logoColor,
					visibleRoutes: true,
					visibleBack: false,
					templateData: null,
				});
			} else {
				setHeader({
					color: value.color,
					logoColor,
					visibleRoutes: false,
					visibleBack: true,
					templateData: value.templateData,
					backLinkUrl: value.backLinkUrl,
				});
			}
		});

		return () => {
			state$.unsubscribe();
		};
	}, []);

	return (
		<>
			<HeaderWrapper color={header.color}>
				<HeaderHome>
					<BackLink
						color={header.color}
						visible={header.visibleBack}
						url={header.backLinkUrl}
					/>
					<HomeLogoLink
						color={header.logoColor}
						visible={!header.visibleBack}
					/>
				</HeaderHome>

				<HeaderToolbar>
					<HeaderRoutes color={header.color} visible={header.visibleRoutes}>
						{routes.map((route, idx) => (
							<NavLink
								key={idx}
								to={route.link}
								className={(navData) =>
									navData.isActive ? 'route route-active' : 'route'
								}>
								{route.name}
							</NavLink>
						))}
						<a
							href={docsUrl}
							target={'_blank'}
							rel="noreferrer noopener"
							className="route">
							Documents
						</a>
					</HeaderRoutes>

					{!header.showNav && !header.templateData ? <div /> : null}

					{header.templateData ? (
						<HeaderTemplateData>{header.templateData}</HeaderTemplateData>
					) : null}

					<HeaderWallet color={header.color} />
				</HeaderToolbar>
			</HeaderWrapper>

			<Burger open={open} setOpen={setOpen} color={header.logoColor} />

			<MobileMenu open={open} setOpen={setOpen} />
		</>
	);
};

export default Header;
