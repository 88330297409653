import styled from 'styled-components';

export const StyledWalletButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	color: #fff;

	&:hover {
		cursor: pointer;
	}
`;
