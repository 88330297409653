export const MESSAGES = {
	CONNECT_WALLET_REQUEST: 'Please connect your wallet',
	NO_COMPARE_DATA: 'Nothing to compare',
	NO_DATA: 'No data',
	FETCH_DATA_ERROR: 'No data fetched',
	POST_DATA_ERROR: 'Can`t post data',
	CHANGE_NETWORK: 'Please change network',
	DAO_TOKEN_PRICE: 'Deposit is impossible because token price is 0',
	UNKNOWN_ERROR: 'Something went wrong',
	APP_ADDRESS_NOT_FOUND: 'App address not found',
	NO_TOKEN_DECIMALS: 'No token decimals',
	NO_TOKENS_ERROR: 'You have no tokens',
	DEPOSIT_SUCCESS: 'You have successfully deposited tokens',
	WITHDRAW_SUCCESS: 'You have successfully request withdraw tokens',
	VOTE_SUCCESS: 'You successfully voted',
	CREATE_STATUS_TITLE: 'Creating your personal DAO',
	FINISHED_TEXT: 'DAO successfully created',
	GO_TO_MANAGERS_TEXT: 'Go to Managers',
	GO_TO_DAO_LIST_TEXT: 'Go to DAO list',
	CONNECT_WALLET_TEXT: 'Connect wallet',
	NO_CONTRACT_PROVIDED: 'No contract provided',
	NO_ACCEPTABLE_TOKENS: 'Dao has no acceptable tokens',
	UPDATE_VOTE_ALLOW: 'You can now vote to update dao agent',
	TRANSFER_VOTE_ALLOW: 'You can now vote to transfer tokens',
	WAIT_FOR_TRANSACTION: 'Please wait end of transaction',
	TRANSACTION_COMPLETED: 'Transaction completed!',
	VOTE_FOR: 'You can now vote for',
	NOT_SUPPORTED_NETWORK:
		'Network that you try connect to is not supported by Weezi',
	REFUND_SUCCESS: 'Refund success',
};

export const CREATE_DAO_WAITING_PHRASES = [
	'Wait for a few moments.',
	'Please, don`t restart page.',
	'Your DAO almost ready.',
];
