import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Layout } from './components/Layout';
import { MessageDialog } from './components/MessageDialog';
import { ToastProvider } from './components/Toast';

const CreateDAO = React.lazy(() => import('./pages/CreateDAO/CreateDAO'));
const ProgressDAO = React.lazy(() => import('./pages/ProgressDAO/ProgressDAO'));
const Depositors = React.lazy(() => import('./pages/Depositors/Depositors'));
const DetailDAO = React.lazy(() => import('./pages/DetailDAO/DetailDAO'));
const MyAccount = React.lazy(() => import('./pages/MyAccount/MyAccount'));
const CompareDAO = React.lazy(() => import('./pages/CompareDAO/CompareDAO'));
const Leaderboard = React.lazy(() => import('./pages/Leaderboard/Leaderboard'));
const Analytics = React.lazy(() => import('./pages/Analytics/Analytics'));

const Managers = React.lazy(() => import('./pages/Managers/Managers/Managers'));
const ManagersHome = React.lazy(() => import('./pages/Managers/Home/Home'));
const ManagersListDAO = React.lazy(() =>
	import('./pages/Managers/ListDAO/ListDAO'),
);

const Manage = React.lazy(() => import('./pages/Manage/Manage/Manage'));
const MangeOverview = React.lazy(() =>
	import('./pages/Manage/Overview/Overview'),
);
const Permissions = React.lazy(() =>
	import('./pages/Manage/Permissions/Permissions'),
);
const ConnectApps = React.lazy(() =>
	import('./pages/Manage/ConnectApps/ConnectApps'),
);
const Tokens = React.lazy(() => import('./pages/Manage/Tokens/Tokens'));
const Deposit = React.lazy(() => import('./pages/Manage/Deposit/Deposit'));
const Voting = React.lazy(() => import('./pages/Manage/Voting/Voting'));
const Rewards = React.lazy(() => import('./pages/Manage/Rewards/Rewards'));
const Redemptions = React.lazy(() =>
	import('./pages/Manage/Redemptions/Redemptions'),
);
const Transfer = React.lazy(() => import('./pages/Manage/Transfer/Transfer'));

function App() {
	return (
		<ToastProvider>
			<MessageDialog />

			<Suspense fallback={null}>
				<Routes>
					<Route path="/create-dao" element={<CreateDAO />} />
					<Route path="/progress-dao/:txHash" element={<ProgressDAO />} />

					<Route path="/" element={<Layout />}>
						{/* <Route path="" element={<Navigate to="/depositors" />} />

						<Route path="/depositors" element={<Depositors />} />
						<Route path="/depositors/:slug_id" element={<DetailDAO />} /> */}

						<Route path="" element={<Depositors />} />
						<Route path="/:slug_id" element={<DetailDAO />} />

						<Route path="/compare" element={<CompareDAO />} />

						<Route path="/my-account" element={<MyAccount />} />

						<Route path="/managers" element={<Managers />}>
							<Route path="" element={<Navigate to="home" />} />
							<Route path="home" element={<ManagersHome />} />
							<Route path="list-dao" element={<ManagersListDAO />} />
						</Route>

						<Route path="/manage/:slug_id" element={<Manage />}>
							<Route path="" element={<Navigate to="overview" />} />
							<Route path="overview" element={<MangeOverview />} />
							<Route path="permissions" element={<Permissions />} />
							<Route path="connect-apps" element={<ConnectApps />} />
							<Route path="tokens/:appAddress" element={<Tokens />} />
							<Route path="deposit/:appAddress" element={<Deposit />} Ï />
							<Route path="voting/:appAddress" element={<Voting />} />
							<Route path="rewards/:appAddress" element={<Rewards />} />
							<Route path="redemptions/:appAddress" element={<Redemptions />} />
							<Route path="transfer" element={<Transfer />} />
						</Route>

						<Route path="/analytics" element={<Analytics />} />

						<Route path="/leaderboard" element={<Leaderboard />} />

						<Route path="*" element={<Navigate to="/depositors" replace />} />
					</Route>
				</Routes>
			</Suspense>
		</ToastProvider>
	);
}

export default App;
