import { take, BehaviorSubject, from } from 'rxjs';
import { MESSAGES } from '../models/messages';
import ApiService from './api.service';
import { AppError } from '../models/app-error';

class BlockchainService {
	initialState = {
		loading: false,
		error: '',
		blockchains: [],
	};

	state = this.initialState;
	state$ = new BehaviorSubject();

	constructor() {
		this.getData();
	}

	getBCDataByNetworkId = async (networkId) => {
		if (this.state.blockchains.length === 0) {
			await this.getDataRaw();
		}

		const bc = this.state.blockchains.filter(
			(x) => +x.chain_id === +networkId,
		)[0];

		if (bc) return bc;
		else throw new AppError(MESSAGES.NOT_SUPPORTED_NETWORK);
	};

	getDataRaw = async () => {
		const result = await ApiService.getBlockchains();

		this.state = {
			...this.state,
			loading: false,
			blockchains: result.body,
		};

		this.state$.next(this.state);
	};

	getData(perPage = 100, page = 1) {
		if (this.state.loading) {
			return;
		}

		this.state = {
			...this.state,
			loading: true,
		};
		this.state$.next(this.state);

		const data$ = from(ApiService.getBlockchains(perPage, page)).pipe(take(1));

		data$.subscribe({
			next: (result) => {
				this.state = {
					...this.state,
					loading: false,
					blockchains: result.body,
				};

				this.state$.next(this.state);
			},
			error: (error) => {
				this.state = {
					...this.state,
					loading: false,
					error: error.message,
				};
				this.state$.next(this.state);
			},
		});
	}

	isMainnet(chainId) {
		switch (chainId) {
			case 1:
			case 137:
			case 56:
				return true;
			default:
				return false;
		}
	}
}

export default new BlockchainService();
