import React from 'react';
import { NavLink } from 'react-router-dom';
import { MobileRoutes, StyledMenu } from './styled';
import { docsUrl, routes } from '../Header/routes';
import { MobileWallet } from './MobileWallet';

const MobileMenu = ({ open, setOpen }) => {
	const closeMenu = () => {
		document.documentElement.style.overflow = 'auto';
		setOpen(false);
	};

	return (
		<StyledMenu open={open}>
			<MobileRoutes>
				{routes.map((route, idx) => (
					<NavLink
						key={idx}
						to={route.link}
						onClick={() => {
							closeMenu();
						}}
						className={(navData) =>
							navData.isActive ? 'route route-active' : 'route'
						}>
						{route.name}
					</NavLink>
				))}
				<a
					href={docsUrl}
					target={'_blank'}
					rel="noreferrer noopener"
					className="route">
					Documents
				</a>
			</MobileRoutes>

			<MobileRoutes>
				<NavLink
					to={'/depositors'}
					onClick={() => {
						closeMenu();
					}}
					className={(navData) =>
						navData.isActive ? 'route route-active' : 'route'
					}>
					Browse
				</NavLink>
				<NavLink
					to={'/my-account'}
					onClick={() => {
						closeMenu();
					}}
					className={(navData) =>
						navData.isActive ? 'route route-active' : 'route'
					}>
					My account
				</NavLink>
			</MobileRoutes>
			<MobileWallet />
		</StyledMenu>
	);
};
export default MobileMenu;
