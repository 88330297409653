import styled from 'styled-components';
import {
	getActiveRouteColor,
	getBgColor,
	getRouteColor,
} from '../../helpers/utils';

export const HeaderWrapper = styled.header`
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 80px;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column: span 12;
	background: ${({ color }) => getBgColor(color)};
`;

export const HeaderHome = styled.nav`
	padding: 28px;
	grid-column: span 2;

	@media (max-width: 992px) {
		grid-column: span 6;
	}
`;

export const HeaderToolbar = styled.div`
	grid-column: span 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 992px) {
		grid-column: span 6;
	}
`;

export const HeaderRoutes = styled.nav`
	padding: 28px;
	display: ${({ visible }) => (visible ? 'flex' : 'none')};
	flex-direction: row;
	justify-content: flex-start;
	gap: 40px;

	.route {
		font-weight: 400;
		font-size: 22px;
		line-height: 24px;
		color: ${({ color }) => getRouteColor(color)};
		text-decoration: none;

		&.route-active {
			color: ${({ color }) => getActiveRouteColor(color)};
		}

		&:hover {
			color: ${({ color }) => getActiveRouteColor(color)};
		}
	}

	@media (max-width: 1536px) {
		.route {
			font-size: 18px;
			line-height: 22px;
		}
	}

	@media (max-width: 1366px) {
		.route {
			font-size: 16px;
			line-height: 20px;
		}
	}

	@media (max-width: 1200px) {
		gap: 15px;
	}

	@media (max-width: 992px) {
		display: none;
	}
`;

export const HeaderTemplateData = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
