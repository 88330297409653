import styled from 'styled-components';
import { COLORS } from '../../../models/colors';

export const StyledMobileWallet = styled.div`
	div {
		font-weight: 400;
		font-size: 48px;
		line-height: 170%;
		letter-spacing: 0.02em;
		color: ${COLORS.GREEN};
	}

	@media (max-width: 576px) {
		div {
			font-size: 32px;
			line-height: 150%;
		}
	}
`;
