export const ENS = {
	1: process.env.REACT_APP_ENS_1,
	4: process.env.REACT_APP_ENS_4,
	5: process.env.REACT_APP_ENS_5,
	56: process.env.REACT_APP_ENS_56,
	97: process.env.REACT_APP_ENS_97,
	137: process.env.REACT_APP_ENS_137,
	43113: process.env.REACT_APP_ENS_43113,
	80001: process.env.REACT_APP_ENS_80001,
};
