import styled from 'styled-components';
import { COLORS } from '../../models/colors';

export const StyledBurger = styled.div`
	display: none;
	position: fixed;
	top: 28px;
	right: 28px;
	flex-direction: column;
	justify-content: space-around;
	width: 24px;
	height: 24px;
	background: transparent;
	border: none;
	cursor: pointer;
	z-index: 11;

	span {
		width: 24px;
		height: 3px;
		background: ${(props) => (props.open ? COLORS.WHITE : props.color)};
		border-radius: 0;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}
		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
		}
		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}

	@media (max-width: 992px) {
		display: flex;
	}
`;
