export const routes = [
	{
		name: 'Leaderboard',
		link: '/leaderboard',
	},
	{
		name: 'For Depositors',
		link: '/',
	},
	{
		name: 'For DAO Managers',
		link: '/managers',
	},
	{
		name: 'Analytics',
		link: '/analytics',
	},
	// {
	// 	name: 'Pricing',
	// 	link: '/pricing',
	// },
];

export const docsUrl = 'https://docs.weezi.io/';
