export const RPC = {
	1: 'https://rpc.ankr.com/eth',
	3: 'https://rpc.ankr.com/eth_ropsten',
	4: 'https://rpc.ankr.com/eth_rinkeby',
	5: 'https://rpc.ankr.com/eth_goerli',
	56: 'https://rpc.ankr.com/bsc',
	97: 'https://rpc.ankr.com/bsc_testnet_chapel',
	137: 'https://rpc.ankr.com/polygon',
	80001: 'https://rpc.ankr.com/polygon_mumbai',
};
