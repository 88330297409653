export class AppError extends Error {
	constructor(message) {
		super();
		this.message = this.#decodeError(message);
	}

	#decodeError(message) {
		if (
			message ===
			'[ethjs-query] while formatting outputs from RPC \'{"value":{"code":-32603,"data":{"code":-32000,"message":"transaction underpriced"}}}\''
		) {
			return 'The gas price is too low. Please enter a greater value and restart the transaction.';
		}
		return message;
	}
}
