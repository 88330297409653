import React from 'react';
import { WalletButton } from '../../Buttons/WalletButton';
import { StyledMobileWallet } from './styled';

const MobileWallet = () => {
	return (
		<StyledMobileWallet>
			<WalletButton />
		</StyledMobileWallet>
	);
};

export default MobileWallet;
