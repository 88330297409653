import { BehaviorSubject } from 'rxjs';

class HeaderService {
	initialState = {
		color: 'green',
		showNav: true,
		templateData: null,
		backLinkUrl: '/',
	};

	state$ = new BehaviorSubject(this.initialState);

	changeHeader(color, showNav, backLinkUrl = '/', templateData) {
		this.state$.next({
			...this.initialState,
			color,
			showNav,
			templateData,
			backLinkUrl,
		});
	}
}

export default new HeaderService();
