export const TOKEN_REQUEST_ABI = [
	{
		constant: true,
		inputs: [],
		name: 'MODIFY_TOKENS_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'hasInitialized',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [],
		name: 'getToken',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_script',
				type: 'bytes',
			},
		],
		name: 'getEVMScriptExecutor',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'tokenManager',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getRecoveryVault',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'SET_TOKEN_MANAGER_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'MAX_ACCEPTED_DEPOSIT_TOKENS',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		name: 'tokenRequests',
		outputs: [
			{
				name: 'requesterAddress',
				type: 'address',
			},
			{
				name: 'depositToken',
				type: 'address',
			},
			{
				name: 'depositAmount',
				type: 'uint256',
			},
			{
				name: 'requestAmount',
				type: 'uint256',
			},
			{
				name: 'status',
				type: 'uint8',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'removeToken',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_tokenRequestId',
				type: 'uint256',
			},
		],
		name: 'getTokenRequest',
		outputs: [
			{
				name: 'requesterAddress',
				type: 'address',
			},
			{
				name: 'depositToken',
				type: 'address',
			},
			{
				name: 'depositAmount',
				type: 'uint256',
			},
			{
				name: 'requestAmount',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_vault',
				type: 'address',
			},
		],
		name: 'setVault',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		name: 'acceptedDepositTokens',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_tokenManager',
				type: 'address',
			},
			{
				name: '_vault',
				type: 'address',
			},
			{
				name: '_acceptedDepositTokens',
				type: 'address[]',
			},
		],
		name: 'initialize',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'nextTokenRequestId',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'FINALISE_TOKEN_REQUEST_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_tokenManager',
				type: 'address',
			},
		],
		name: 'setTokenManager',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: 'token',
				type: 'address',
			},
		],
		name: 'allowRecoverability',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'appId',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_depositToken',
				type: 'address',
			},
			{
				name: '_depositAmount',
				type: 'uint256',
			},
			{
				name: '_requestAmount',
				type: 'uint256',
			},
			{
				name: '_reference',
				type: 'string',
			},
		],
		name: 'createTokenRequest',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: true,
		stateMutability: 'payable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getInitializationBlock',
		outputs: [
			{
				name: '',
				type: 'uint256',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'transferToVault',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [
			{
				name: '_sender',
				type: 'address',
			},
			{
				name: '_role',
				type: 'bytes32',
			},
			{
				name: '_params',
				type: 'uint256[]',
			},
		],
		name: 'canPerform',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getEVMScriptRegistry',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_tokenRequestId',
				type: 'uint256',
			},
		],
		name: 'finaliseTokenRequest',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_tokenRequestId',
				type: 'uint256',
			},
		],
		name: 'refundTokenRequest',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: false,
		inputs: [
			{
				name: '_token',
				type: 'address',
			},
		],
		name: 'addToken',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'kernel',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'isPetrified',
		outputs: [
			{
				name: '',
				type: 'bool',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'getAcceptedDepositTokens',
		outputs: [
			{
				name: '',
				type: 'address[]',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'SET_VAULT_ROLE',
		outputs: [
			{
				name: '',
				type: 'bytes32',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		constant: true,
		inputs: [],
		name: 'vault',
		outputs: [
			{
				name: '',
				type: 'address',
			},
		],
		payable: false,
		stateMutability: 'view',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				name: 'tokenManager',
				type: 'address',
			},
		],
		name: 'SetTokenManager',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				name: 'vault',
				type: 'address',
			},
		],
		name: 'SetVault',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
		],
		name: 'TokenAdded',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
		],
		name: 'TokenRemoved',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				name: 'requestId',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'requesterAddress',
				type: 'address',
			},
			{
				indexed: false,
				name: 'depositToken',
				type: 'address',
			},
			{
				indexed: false,
				name: 'depositAmount',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'requestAmount',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'reference',
				type: 'string',
			},
		],
		name: 'TokenRequestCreated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				name: 'requestId',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'refundToAddress',
				type: 'address',
			},
			{
				indexed: false,
				name: 'refundToken',
				type: 'address',
			},
			{
				indexed: false,
				name: 'refundAmount',
				type: 'uint256',
			},
		],
		name: 'TokenRequestRefunded',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				name: 'requestId',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'requester',
				type: 'address',
			},
			{
				indexed: false,
				name: 'depositToken',
				type: 'address',
			},
			{
				indexed: false,
				name: 'depositAmount',
				type: 'uint256',
			},
			{
				indexed: false,
				name: 'requestAmount',
				type: 'uint256',
			},
		],
		name: 'TokenRequestFinalised',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'executor',
				type: 'address',
			},
			{
				indexed: false,
				name: 'script',
				type: 'bytes',
			},
			{
				indexed: false,
				name: 'input',
				type: 'bytes',
			},
			{
				indexed: false,
				name: 'returnData',
				type: 'bytes',
			},
		],
		name: 'ScriptResult',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				name: 'vault',
				type: 'address',
			},
			{
				indexed: true,
				name: 'token',
				type: 'address',
			},
			{
				indexed: false,
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'RecoverToVault',
		type: 'event',
	},
];
